@import "~@coreui/coreui/scss/coreui.scss";
@import "react-toastify/dist/ReactToastify.css";
@import "react-confirm-alert/src/react-confirm-alert.css";

$primary:#367fa9;
.c-sidebar {
  color: #fff;
  background:#222d32;
}

.c-sidebar .c-sidebar-brand {
  color: #fff;
  background: $primary;
}

.c-sidebar .c-sidebar-minimizer {
  background-color: $primary;
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  color: #fff;
  background: $primary;
}

.c-active {
  background: #6872b3;
}

.required-class:after {
  content: "*";
  color: red;
  margin-left: 3px;
  font-size: 14px;
}

.custom-dd-input {
  border: none;
}

.custom-dd-input::after {
  right: 3%;
  top: 15px;
  position: absolute;
}